<template>
  <div class="invest">
    <div class="container">
      <div class="top">
        <div class="name font-lg">신청 완료</div>
        <div class="txt font-base">
          <span>{{ $route.query.mock !== undefined ? "모의" : "후원형" }} 프로젝트가 정상적으로 신청되었습니다.</span>
        </div>
      </div>
      <div class="illustration">
        <span class="img" style="background-image: url(/assets/img/page.invest.done.svg)"></span>
      </div>
      <div class="act">
        <router-link to="/" class="btn btn-bordered-secondary font-sm mr-2">홈으로 이동</router-link>
        <router-link :to="`/mypage/open/${$route.query.mock === undefined ? 'reward' : 'mock'}`" class="btn btn-bordered-point font-sm">마이페이지로 이동</router-link>
      </div>
      <ul class="guide bottom tight font-sm">
        <li>
          <span>신청하신 프로젝트 내용 확인과 수정은 로그인 후 마이페이지 > 진행 프로젝트에서 가능합니다.</span>
        </li>
        <li>
          <span>프로젝트 내용은 오마이컴퍼니  프로젝트 매니저 검토 후 피드백을 드리며 프로젝트 최종 완성 후 오픈하게 됩니다.</span>
        </li>
        <li>
          <span>프로젝트 검토는 영업일 기준  평균 2일이 소요됩니다.</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageOrderInvest";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.invest {
  padding: $px39 0;

  header {
    display: none;
    position: relative;
    max-width: 620px;
    margin: $px15 auto $px30 auto;
    padding: $px25 $px98 $px25 $px25;

    .img {
      position: absolute;
      top: $px25;
      right: $px25;
      width: $px80;
      height: $px58;
      background-color: $colorBackground;
      border: $px1 solid #eee;
      border-radius: $px4;
    }

    .title {
      display: inline-block;
      font-weight: 600;
      margin-bottom: $px3;
      text-decoration: none;
    }
  }

  .container {
    max-width: 767px;

    .top {
      .name {
        padding-bottom: $px5;
        display: inline-block;
        width: $px420;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        max-width: 100%;
      }
    }

    .illustration {
      padding: $px35;
      text-align: center;

      > .img {
        width: $px400;
        height: $px373;
      }
    }

    .act {
      text-align: center;
      padding-bottom: $px50;

      > .btn {
        padding: $px15 $px25;
      }
    }

    .guide {
      background: $colorBackground;
      padding: $px25;
      border: $px1 solid #eee;
      border-radius: $px4;

      li {
        position: relative;
        padding-left: $px10;
        margin-bottom: $px5;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: "-";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  @media(max-width: 767px) {
    padding-left: $px20;
    padding-right: $px20;

    .container {
      .row > div {
        table {
          margin-bottom: $px25;

          th, td {
            padding-top: $px10;
            padding-bottom: $px10;
          }
        }

        &.illustration > .img {
          width: $px261;
          height: $px243;
        }
      }

      .act {
        padding-bottom: $px30;
      }
    }
  }

  @media (max-width: 767px) {
    .container .illustration {
      padding: $px30 $px35 $px35 $px35;

      > .img {
        height: $px230;
      }
    }
  }
}
</style>